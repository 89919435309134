<!-- Details Panel -->
<div class="details-panel" *ngIf="account">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ account.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="1x" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="1x" class="d-lg-none"></fa-icon></button
                >{{ account.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="1x"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <button
                                class="text-primary"
                                (click)="impersonate()"
                                title="{{
                                    ((isZixiSupport$ | async) || (isZixiAdmin$ | async)) && account.is_enabled && !account.allow_zixi_support
                                        ? ('ACCESS_DISABLED' | translate)
                                        : ((isZixiSupport$ | async) || (isZixiAdmin$ | async)) && !account.is_enabled
                                        ? ('ACCOUNT_DISABLED' | translate)
                                        : ('SUPPORT_ACCESS' | translate)
                                }}"
                                [disabled]="!((isZixiSupport$ | async) || (isZixiAdmin$ | async)) || !account.is_enabled || !account.allow_zixi_support"
                                mat-menu-item
                            >
                                <fa-icon icon="user-secret" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "SUPPORT_ACCESS" | translate }}
                            </button>
                            <button (click)="editAccount()" class="text-primary" title="{{ 'EDIT' | translate }}" mat-menu-item>
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                [ngClass]="account.is_enabled ? 'text-warning' : 'text-success'"
                                (click)="toggle()"
                                title="{{ (account.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
                                [disabled]="account.dns_prefix === 'zixi' || (isZixiAdmin$ | async) === false"
                                mat-menu-item
                            >
                                <fa-icon icon="power-off" size="sm" *ngIf="!account.is_enabled" [fixedWidth]="true"></fa-icon>
                                <fa-icon icon="ban" size="sm" *ngIf="account.is_enabled" [fixedWidth]="true"></fa-icon>
                                {{ (account.is_enabled ? "DISABLE" : "ENABLE") | translate }}
                            </button>
                            <button (click)="deleteAccount()" class="text-danger" title="{{ 'DELETE' | translate }}" mat-menu-item>
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button></mat-menu
                        >
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <!-- Manage -->
                <button
                    type="button"
                    class="btn btn-outline-primary mb-2 me-2 disable-show-tooltip"
                    (click)="impersonate()"
                    title="{{
                        ((isZixiSupport$ | async) || (isZixiAdmin$ | async)) && account.is_enabled && !account.allow_zixi_support
                            ? ('ACCESS_DISABLED' | translate)
                            : ((isZixiSupport$ | async) || (isZixiAdmin$ | async)) && !account.is_enabled
                            ? ('ACCOUNT_DISABLED' | translate)
                            : !account.is_enabled || !account.allow_zixi_support
                            ? ('USER_BLOCKED_OPERATION' | translate)
                            : ('SUPPORT_ACCESS' | translate)
                    }}"
                    [disabled]="!((isZixiSupport$ | async) || (isZixiAdmin$ | async)) || !account.is_enabled || !account.allow_zixi_support"
                >
                    <fa-icon icon="user-secret" size="sm"></fa-icon>
                    <span class="d-none d-xxl-inline-block ms-1">{{ "SUPPORT_ACCESS" | translate }}</span>
                </button>

                <div class="btn-group mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="editAccount()"
                        title="{{ 'EDIT' | translate }}"
                        [disabled]="(isZixiAdmin$ | async) === false"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="account.is_enabled ? 'btn-outline-warning' : 'btn-outline-success'"
                        (click)="toggle()"
                        title="{{ (account.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
                        [disabled]="account.dns_prefix === 'zixi' || (isZixiAdmin$ | async) === false"
                    >
                        <fa-icon icon="power-off" size="sm" *ngIf="!account.is_enabled"></fa-icon>
                        <fa-icon icon="ban" size="sm" *ngIf="account.is_enabled"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ (account.is_enabled ? "DISABLE" : "ENABLE") | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        (click)="deleteAccount()"
                        title="{{ 'DELETE' | translate }}"
                        [disabled]="(isZixiAdmin$ | async) === false || account.dns_prefix === 'zixi'"
                    >
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions">
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="details-form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>NAME</h5>
                                            <div class="col-12">
                                                <span>{{ account.name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>DNS_PREFIX</h5>
                                            <div class="col-12">
                                                <span>{{ account.dns_prefix }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>CQA</h5>
                                            <div class="col-12">
                                                <span>{{ (account.content_analysis ? "YES" : "NO") | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>AUTOMATION</h5>
                                            <div class="col-12">
                                                <span>{{ (account.automation ? "YES" : "NO") | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>LIVE_EVENTS</h5>
                                            <div class="col-12">
                                                <span>{{ (account.live_events ? "YES" : "NO") | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>AGENTZ</h5>
                                            <div class="col-12">
                                                <span>{{ (account.allow_agentz ? "ENABLED" : "DISABLED") | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Notes -->
                <ngb-accordion #notesAccordion="ngbAccordion" activeIds="ngb-panel-notes" *ngIf="account">
                    <ngb-panel id="ngb-panel-notes">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'NOTES' | translate }}">NOTES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-advanced-notes [model]="account" [type]="'customers'" [id]="account.id" [canEdit]="true"></zx-advanced-notes>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
